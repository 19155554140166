@import '../modules/gallery';

.gallery {
  @include gallery;

  &__album {
    @include gallery__album;
  }

  &__album-cover {
    @include gallery__album-cover;
  }

  &__image-wrapper {
    @include gallery__image-wrapper;
  }

  &__image {
    @include gallery__image;
  }

  &__button {
    @include gallery__button;
  }
}
