@import '../modules/header';

header {
  @include header;
}

.header__logo {
  @include header__logo;

  &-image {
    @include header__logo-image;
  }

  &-text {
    @include header__logo-text;
  }
}

.header__navigation {
  @include header__navigation;

  .navigation__link {
    @include header__navigation-link;
  }

  .navigation__menu-button {
    @include header__navigation-menu-button;
  }
}
