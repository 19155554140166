@mixin footer {
  position: absolute;
  bottom: 0;
  width: $width-wrapper;
  height: $height-footer;
  padding: 50px 25px;
  box-sizing: border-box;
  color: $c-footer-text;
  background-color: $c-footer-bg;
  font-size: 0.95em;

  .page-content {
    min-width: 300px;
    padding: 0;
    height: $height-footer - 100px;
  }
}

@mixin footer__mwr-logo {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 150px;
  height: 75px;
  overflow: auto;
  background-image: url('../assets/img/mw-logo-500-w.png');
  background-size: 100%;
}

@mixin footer__left {
  float: left;
}

@mixin footer__right {
  float: right;
  text-align: right;
  clear: right;
  margin-bottom: 5px;
}

@mixin footer__top {
  overflow: auto;
  font-size: 24px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px $c-footer-lines;

  .footer__right {
    margin: 0px;
  }

  .footer__fb i {
    transition: all .25s ease-in-out;
    &:hover {
      color: $c-hover-facebook;
    }
  }

  .footer__tw i {
    transition: all .25s ease-in-out;
    &:hover {
      color: $c-hover-twitter;
    }
  }

  .footer__ig i {
    transition: all .25s ease-in-out;
    &:hover {
      color: $c-hover-instagram;
    }
  }

  .footer__yt i {
    transition: all .25s ease-in-out;
    &:hover {
      color: $c-hover-youtube;
    }
  }

  .footer__tt i {
    transition: all .25s ease-in-out;
    &:hover {
      color: $c-hover-tiktok;
    }
  }

  .footer__em i {
    transition: all .25s ease-in-out;
    &:hover {
      color: $c-hover-email;
    }
  }

  .footer__rss i {
    transition: all .25s ease-in-out;
    &:hover {
      color: $c-hover-rss;
    }
  }

  .navigation__link {
    text-decoration: none;
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }

    i {
      color: $c-footer-text;
    }
  }
}

@mixin footer__bottom {
  overflow: auto;
  margin-bottom: 15px;
  color: $c-footer-subtext;

  a {
    color: $c-footer-link;
    text-decoration: none;
    transition: all .25s ease-in-out;

    &:hover {
      color: $c-white;
    }
  }

  a.navigation__link {
    text-decoration: none;
    display: block;
    text-decoration: none;
    margin-bottom: 5px;

    &:hover {
      span {
        transition: all .25s ease-in-out;
        color: $c-white;
      }
    }

    &:last-child {
      margin-bottom: 5px;
    }

    span {
      transition: all .25s ease-in-out;
      color: $c-footer-subtext;
    }

    i {
      display: none;
    }
  }
}
