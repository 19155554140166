@mixin partners {
  overflow: auto;
  padding: 50px 0px;
}

@mixin partner__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  overflow: hidden;
}

@mixin partner {
  display: inline-block;
  float: left;
  width: 25%;
  height: auto;
  filter: saturate(0%);
  opacity: 0.85;
  transition: filter 0.25s ease-in-out, opacity 0.25s ease-in-out;
  text-align: center;

  img {
    transition: transform 0.25s ease-in-out;
    width: 90%;
    height: auto;
  }

  &:hover {
    filter: saturate(100%);

    img {
      transform: scale(1.1);
      opacity: 1;
    }
  }

  @media (max-width: 700px) {
    width: 200px;
    text-align: center;
  }

  @media (max-width: $m-mobile) {
    width: 150px;
    text-align: center;
  }
}
