@import '../modules/news';

.news {
  @include news;

  &__more-news {
    @include news__more-news;
  }

  &__posts-older {
    @include news__posts-older;
  }

  &__post {
    @include news__post;

    &-latest {
      @include news__post-latest;
    }

    &-older {
      @include news__post-older;
    }

    &-title {
      @include news__post-title;
    }

    &-short-description {
      @include news__post-short-description;
    }

    &-date {
      @include news__post-date;
    }

    &-buttons {
      @include news__post-buttons;
    }

    &-action {
      @include news__post-action;
    }

    &-tags {
      @include news__post-tags;
    }

    &-tag {
      @include news__post-tag;
    }
  }
}

.pagination {
  @include pagination;

  &__section-left {
    @include pagination__section-left;
  }

  &__section-centre {
    @include pagination__section-centre;
  }

  &__section-right {
    @include pagination__section-right;
  }

  &__previous {
    @include pagination__previous;
  }

  &__page-number {
    @include pagination__page-number;
  }

  &__next {
    @include pagination__next;
  }
}
