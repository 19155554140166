@mixin page-content {
  position: relative;
  width: $width-main;
  min-width: $min-width-site;
  margin: 0 auto;
  padding: 25px 25px $height-footer + $padding-footer 25px;

  @media(max-width: $m-tablet) {
    width: 100%;
    box-sizing: border-box;
  }
}

@mixin page-content__title {
  text-transform: uppercase;
  font-size: 1.5em;
  margin: 0;
  padding: 25px 0px 50px 0px;
  font-weight: 500;
  color: $c-page-content-title;

  i {
    margin-right: 10px;
  }

  @media(max-width: $m-tablet) {
    padding: 10px 0px 35px 0px;
    text-align: center;
  }
}
