@import '../modules/drawer';

.drawer {
  @include drawer;

  &__header {
    @include drawer__header;
  }

  &::-webkit-scrollbar {
    @include webkit-scrollbar;
  }

  &::-webkit-scrollbar-button {
    @include webkit-scrollbar-button;
  }

  &::-webkit-scrollbar-track {
    @include webkit-scrollbar-track;
  }

  &::-webkit-scrollbar-track-piece {
    @include webkit-scrollbar-track-piece;
  }

  &::-webkit-scrollbar-thumb {
    @include webkit-scrollbar-thumb;
  }

  &::-webkit-scrollbar-corner {
    @include webkit-scrollbar-corner;
  }

  &::-webkit-resizer {
    @include webkit-resizer;
  }
}

.drawer__menu {
  @include drawer__menu;

  .navigation__link {
    @include drawer__menu-item;

    i {
      @include drawer__menu-item-icon;
    }

    span {
      @include drawer__menu-item-text;
    }
  }
}
