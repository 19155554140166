@import '../modules/buttons';

.button {
  @include button;

  &-large {
    @include button-large;
  }

  &__white {
    @include button__white;
  }

  &__black {
    @include button__black;
  }

  &__orange {
    @include button__orange;
  }

  &__cyan {
    @include button__cyan;
  }

  &__rounded {
    @include button__rounded;
  }

  &__shadow {
    @include button__shadow;
  }
}
