@mixin gallery {
  overflow: auto;
}

@mixin gallery__album {
  overflow: auto;
  width: calc(50% - 13px);
  float: left;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  background-color: $c-gallery-album;
  margin-bottom: 26px;

  h3 {
    margin: 10px 0px;
    text-align: left;
  }

  a.button {
    float: left;

    i {
      @media(max-width: 600px) {
        margin-right: 0px;
      }

      @media(max-width: 518px) {
        margin-right: 8px;
      }
    }

    span {
      @media(max-width: 600px) {
        display: none;
      }

      @media(max-width: 518px) {
        display: inline;
      }
    }
  }

  p {
    padding: 12px 0px;
    margin: 0;
    float: right;

    i {
      margin-right: 5px;
    }
  }

  &:nth-child(even) {
    float: right;
  }

  @media(max-width: 518px) {
    float: none;
    width: 100%;
  }
}

@mixin gallery__album-cover {
  max-height: 100%;
  max-width: 100%;
}

@mixin gallery__image-wrapper {
  float: left;
  width: calc(50% - 13px);
  margin-bottom: 26px;

  &:nth-child(even) {
    float: right;
  }

  @media(max-width: 518px) {
    float: none;
    width: 100%;
  }
}

@mixin gallery__image {
  max-height: 100%;
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
}

@mixin gallery__button {

  margin-bottom: 25px;

  @media(max-width: $m-tablet) {
    text-align: center;
  }
}
