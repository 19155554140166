@mixin calendar {
}

@mixin calendar__event {
  overflow: hidden;
  position: relative;
  border-bottom: solid 1px $c-calendar-next-border;
  padding-bottom: 25px;
  margin-bottom: 25px;

  &:last-child {
    border-bottom: none;
  }
}

@mixin calendar__icon {
  width: 65px;
  height: 65px;
  float: left;
  clear: left;
  margin-right: 16px;

  @media(max-width: $m-mobile) {
    width: 55px;
    height: 55px;
  }

  img {
    width: 100%;
  }
}

@mixin calendar__caption {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 16px;
  color: $c-orange;
}

@mixin calendar__details {
  float: left;
  width: 70%;

  @media(max-width: 600px) {
    width: 100%;
  }
}

@mixin calendar__details-date {
  font-weight: 500;
  margin-bottom: 5px;
}

@mixin calendar__details-title {
  width: auto;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2em;
  line-height: 1em;
  margin-bottom: 5px;
  color: $c-calendar-title;

  @media(max-width: 600px) {
    font-size: 1.5em;
  }
}

@mixin calendar__details-title-organiser {
  display: block;
}

@mixin calendar__details-title-series {
  display: block;
}

@mixin calendar__details-round {
  font-size: 1.5em;
  font-weight: 300;
  margin-bottom: 16px;

  @media(max-width: 600px) {
    font-size: 1.25em;
  }
}

@mixin calendar__details-circuit {
  margin-bottom: 4px;

  @media(max-width: $m-mobile) {
    font-size: 0.8em;
  }

  i {
    width: 30px;
    text-align: center;

    @media(max-width: $m-mobile) {
      width: 26px;
    }
  }
}

@mixin calendar__details-website {
  margin-bottom: 4px;

  i {
    width: 30px;
    text-align: center;

    @media(max-width: $m-mobile) {
      width: 26px;
    }
  }

  a {
    color: $c-grey;
    // text-decoration: none;
  }

  @media(max-width: $m-mobile) {
    font-size: 0.8em;
  }
}

@mixin calendar__details-tickets {
  i {
    width: 30px;
    text-align: center;

    @media(max-width: $m-mobile) {
      width: 26px;
    }
  }

  a {
    color: $c-grey;
  }

  @media(max-width: $m-mobile) {
    font-size: 0.8em;
  }
}

@mixin calendar__map {
  pointer-events: none;
  float: right;
  width: 25%;

  img {
    width: 100%;
  }

  @media(max-width: 600px) {
    opacity: 0.1;
    position: absolute;
    right: 0;
    width: 275px;
  }

  @media(max-width: $m-mobile) {
    width: 250px;
  }
}

@mixin calendar__upcoming {
  overflow: auto;
}
