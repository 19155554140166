@import "../modules/partnership";

.partnership {
  @include partnership;

  &__presentation {
    @include partnership__presentation;
  }

  &__text {
    @include partnership__text;
  }
}
