@mixin drawer {
  z-index: 3;
  position: fixed;
  top: 0;
  left: -$width-drawer;
  width: $width-drawer;
  height: 100%;
  background-color: $c-drawer;
  transition-property: left;
  transition-duration: $td-drawer;
  transition-timing-function: $ttf-drawer;
  overflow: visible;
  overflow-x: hidden;
  overflow-y: auto;

  &.is-open {
    left: 0;
    box-shadow: 10px 0px 30px -10px rgba(0,0,0,0.5);
  }
}

@mixin drawer__header {
  display: block;
  width: $width-drawer;
  height: $height-drawer-header;
  background:
    url('../assets/img/mw-logo-256.png'),
    $c-drawer-header
  ;
  background-size:
    80px 40px,
    $width-drawer $height-drawer-header
  ;
  background-repeat: no-repeat;
  background-position: 50%;
}

@mixin drawer__menu {
  border-top: solid 1px $c-dark-grey;

  &:nth-child(2) {
    border-top: 0;
  }

  h2 {
    height: 64px;
    line-height: 64px;
    font-weight: 300;
    margin: 0px;
    padding-left: 40px;
    color: $c-drawer-text;
  }
}

@mixin drawer__menu-item {
  display: block;
  box-sizing: border-box;
  padding: 18px 18px 18px 40px;
  color: $c-drawer-text;
  width: 100%;
  text-decoration: none;

  &:hover {
    background-color: $c-darker-grey;
  }
}

@mixin drawer__menu-item-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  padding-right: 16px;
  color: $c-drawer-icon;
}

@mixin drawer__menu-item-text {
  display: inline-block;
  height: 24px;
  line-height: 24px;
}

@mixin webkit-scrollbar {

  background-color: transparent;
  opacity: 0;

  &:hover {
    opacity: 1;
    background-color: $scrollbar-bg;
  }

  &:horizontal {
    height: $scrollbar-thickness;
  }

  &:vertical {
    width: $scrollbar-thickness;
  }
}

@mixin webkit-scrollbar-button {
  display: none;
}

@mixin webkit-scrollbar-track {
  background-color: transparent;
}

@mixin webkit-scrollbar-track-piece {}

@mixin webkit-scrollbar-thumb {
  background-color: $scrollbar-bg;
  border-radius: $scrollbar-border-radius;

  &:hover {
    background-color: $scrollbar-bg-alt;
  }
}

@mixin webkit-scrollbar-corner {
  background-color: transparent;
}

@mixin webkit-resizer {}
