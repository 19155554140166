@import '../modules/calendar';

.calendar {
  @include calendar;

  &__event {
    @include calendar__event;
  }

  &__icon {
    @include calendar__icon;
  }

  &__caption {
    @include calendar__caption;
  }

  &__details {
    @include calendar__details;

    &-date {
      @include calendar__details-date;
    }

    &-title {
      @include calendar__details-title;

      &-organiser {
        @include calendar__details-title-organiser;
      }

      &-series {
        @include calendar__details-title-series;
      }
    }

    &-round {
      @include calendar__details-round;
    }

    &-circuit {
      @include calendar__details-circuit;
    }

    &-website {
      @include calendar__details-website;
    }

    &-tickets {
      @include calendar__details-tickets;
    }
  }

  &__map {
    @include calendar__map;
  }

  &__upcoming {
    @include calendar__upcoming;
  }
}
