@mixin partnership {
  overflow: auto;
  margin-bottom: 15px;

  p a {
    color: $c-contact-link;
    text-decoration-color: $c-macbook;

    &:hover {
      color: $c-contact-link-hover;
    }
  }
}

@mixin partnership__presentation {
  img {
    width: 30%;
    height: auto;
    float: left;

    @media (max-width: $width-content) {
      display: none;
    }
  }
}

@mixin partnership__text {
  box-sizing: border-box;
  padding-left: 25px;
  margin-top: 25px;
  width: 70%;
  float: left;

  @media (max-width: $width-content) {
    width: 100%;
    float: none;
    padding-left: 0px;
  }
}
