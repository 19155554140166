@import '../modules/footer';

footer {
  @include footer;
}

.footer {
  &__mwr-logo {
    @include footer__mwr-logo;
  }


  &__left {
    @include footer__left;
  }

  &__right {
    @include footer__right;
  }

  &__top{
    @include footer__top;
  }

  &__bottom {
    @include footer__bottom;
  }
}
