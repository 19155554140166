// Colours
$c-darkest-grey:          #101010;
$c-darker-grey:           #151515;
$c-dark-grey:             #202020;
$c-grey:                  #303030;
$c-graphite:              #757575;
$c-slate:                 #B0B0B0;
$c-macbook:               #CCCCCC;
$c-light-grey:            #F5F5F5;
$c-white:                 #FFFFFF;
$c-orange:                #FF6600;
$c-red:                   #FF3300;
$c-cyan:                  #0099FF;
$c-blue:                  #0066FF;

// Fonts
$base-font-family:        'Roboto', sans-serif !default;
$base-font-size:          1em !default;
$base-font-weight:        300 !default;
$base-font-colour:        $c-dark-grey !default;

// Site properties
$c-site-background:       $c-white;
$width-site:              100%;
$min-width-site:          350px;
$width-content:           950px;

// Media queries
$m-tablet:                $width-content;
$m-mobile:                500px;

// Mixins
@mixin timing-function($prop) {
  transition-property: $prop;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

// Import component settings
@import
  'settings/buttons',
  'settings/wrapper',
  'settings/header',
  'settings/drawer',
  'settings/next-event',
  'settings/main',
  'settings/page-content',
  'settings/news',
  'settings/gallery',
  'settings/calendar',
  'settings/about',
  'settings/contact',
  'settings/post',
  'settings/partners',
  'settings/footer',
  'settings/facebook'
;
