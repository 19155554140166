@import '../modules/post';

.post {
  &__header {
    @include post__header;
  }

  &__meta {
    @include post__meta;
  }

  &__date {
    @include post__date;
  }

  &__authors {
    @include post__authors;
  }

  &__tag {
    @include post__tag;
  }

  &__banner {
    @include post__banner;
  }

  &__content {
    @include post__content;
  }
}
