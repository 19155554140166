// Colours
$c-drawer:                $c-darkest-grey;
$c-drawer-header:         $c-darker-grey;
$c-drawer-icon:           $c-graphite;
$c-drawer-text:           $c-macbook;

// Dimensions
$width-drawer:            250px;
$height-drawer-header:    150px;

// Transitions
$td-drawer:               .5s;
$ttf-drawer:              cubic-bezier(0.4, 0, 0.2, 1);

// Scrollbar settings
$scrollbar-thickness:     8px;
$scrollbar-border-radius: 0px;
$scrollbar-bg:            rgba(255,255,255,.1);
$scrollbar-bg-alt:        rgba(255,255,255,.3);
