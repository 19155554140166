@mixin post__header {

  h1 {
    padding-bottom: 0px;
  }
}

@mixin post__meta {
  margin-top: 5px;
  padding-bottom: 25px;
  font-weight: 300;

  @media(max-width: $m-tablet) {
    text-align: center;
  }
}

@mixin post__date {
  display: block;
  margin-bottom: 10px;
}

@mixin post__authors {
  color: $c-slate;
  a {
    color: $c-slate;
    text-decoration-color: $c-macbook;
  }
}

@mixin post__tag {
  box-shadow: none;
  margin-right: 8px;
  padding: 5px 8px;
  font-size: 0.75em;
  background-color: rgba(0,0,0,.05);
  border-color: transparent;

  &:last-child {
    margin-right: 0;
  }
}

@mixin post__banner {
  width: 100%;
  margin-bottom: 25px;
}

@mixin post__content {
  text-align: justify;
  line-height: 1.6;

  a {
    color: $c-post-link;
    text-decoration-color: $c-macbook;

    &:hover {
      color: $c-post-link-hover;
    }
  }

  img {
    width: 100%;
  }

  h3 a {
    color: $c-large-link;
    transition: .25s color ease-in-out;

    &:hover {
      color: $c-large-link-hover;
    }
  }
}
