// Colours
$c-footer-bg:             $c-darker-grey;
$c-footer-link:           $c-white;
$c-footer-text:           $c-light-grey;
$c-footer-link:           $c-slate;
$c-footer-subtext:        $c-graphite;
$c-footer-lines:          $c-grey;
$c-hover-instagram:       $c-macbook;
$c-hover-twitter:         #50ABF1;
$c-hover-facebook:        #3A579A;
$c-hover-youtube:         #E02A25;
$c-hover-tiktok:          #F6014E;
$c-hover-email:           $c-macbook;
$c-hover-rss:             $c-orange;

// Dimensions
$width-footer:            $width-wrapper;
$height-footer:           360px;
$padding-footer:          25px;

// Transitions
$t-button:                0s ease-in-out;
