@mixin button {
  display: inline-block;
  padding: 10px 16px;
  text-decoration: none;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 500;

  i {
    margin-right: 8px;
  }
}

@mixin button-large {
  @include button;

  padding: 16px 26px;
}

@mixin button__white {
  @include button-template($c-white, $c-dark-grey);

  transition: .25s all ease-in-out;

  &:hover {
    background-color: $c-white-hover;
    border-color: $c-white-hover;
  }

  &-outline {
    @include button-outline-template($c-white, $c-white);

    transition: .25s background-color ease-in-out;

    &:hover {
      background-color: $c-white;
      color: $c-dark-grey;
    }
  }
}

@mixin button__black {
  @include button-template($c-dark-grey, $c-white);

  transition: .25s all ease-in-out;

  &:hover {
    background-color: $c-black-hover;
    border-color: $c-black-hover;
  }

  &-outline {
    @include button-outline-template($c-dark-grey, $c-dark-grey);

    transition: .25s background-color ease-in-out;

    &:hover {
      background-color: $c-dark-grey;
      color: $c-white;
    }

    &[disabled] {

      pointer-events: none;
      opacity: 0.25;

      &:hover {
        background-color: transparent;
        color: $c-dark-grey;
        border-color: $c-dark-grey;
      }
    }
  }
}

@mixin button__orange {
  @include button-template($c-orange, $c-white);

  &-outline {
    @include button-outline-template($c-orange, $c-white);
  }
}

@mixin button__cyan {
  @include button-template($c-cyan, $c-white);

  transition: .25s all ease-in-out;

  &:hover {
    background-color: $c-cyan-hover;
    border-color: $c-cyan-hover;
  }

  &-outline {
    @include button-outline-template($c-cyan, $c-cyan);
  }
}

@mixin button__rounded {
  border-radius: 4px;
}

@mixin button__shadow {
  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);
}

@mixin button-template($bg, $text) {
  border: solid 2px $bg;
  background-color: $bg;
  color: $text;
}

@mixin button-outline-template($bg, $text) {
  border: solid 2px $bg;
  background-color: transparent;
  color: $text;
}
