@mixin next-event {
  width: 100%;
  padding: $height-header + 50px 25px 50px 25px;
  box-sizing: border-box;
  overflow: auto;
  background-color: $c-next-event-bg;
  background: url("../assets/img/events/knockhill-event.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  color: $c-next-event-text;
  overflow: hidden;

  .page-content {
    overflow: visible;
    margin-top: 0px;
  }

  .page-content {
    padding: 0px;
    min-width: 0px;
  }

  @media (max-width: $m-tablet) {
    padding: $height-header + 25px 25px 50px 25px;
  }
}

@mixin next-event__details {
  position: relative;
  width: 540px;
  float: left;
  box-sizing: border-box;

  @media (max-width: $m-tablet) {
    // position: static;
    width: 100%;
  }

  @media (max-width: 800px) {
    position: relative;
    width: 100%;
  }
}

@mixin next-event__details-caption {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 500;
  padding-left: 2px;
  margin-bottom: 5px;
  color: $c-orange;
  animation: 2s next-event-1 ease-in-out;
  animation-delay: 0;
}
@keyframes next-event-1 {
  0% {
    opacity: 0;
  }
  10% {
    margin-left: -25px;
    opacity: 0;
  }
  40% {
    margin-left: 0px;
    opacity: 1;
  }
}

@mixin next-event__details-series {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2.5em;
  line-height: 1em;
  margin-bottom: 5px;
  color: $c-next-event-title;
  animation: 2s next-event-2 ease-in-out;

  @media (max-width: $m-tablet) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    font-size: 2em;
  }
}
@keyframes next-event-2 {
  0% {
    opacity: 0;
  }
  20% {
    margin-left: -25px;
    opacity: 0;
  }
  50% {
    margin-left: 0px;
    opacity: 1;
  }
}

@mixin next-event__details-round {
  font-size: 1.5em;
  font-weight: 300;
  margin-bottom: 16px;
  animation: 2s next-event-3 ease-in-out;
}
@keyframes next-event-3 {
  0% {
    opacity: 0;
  }
  30% {
    margin-left: -25px;
    opacity: 0;
  }
  60% {
    margin-left: 0px;
    opacity: 1;
  }
}

@mixin next-event__details-circuit {
  font-size: 1.5em;
  font-weight: 500;
  animation: 2s next-event-4 ease-in-out;
}
@keyframes next-event-4 {
  0% {
    opacity: 0;
  }
  40% {
    margin-left: -25px;
    opacity: 0;
  }
  70% {
    margin-left: 0px;
    opacity: 1;
  }
}

@mixin next-event__details-date {
  font-weight: 300;
  animation: 2s next-event-5 ease-in-out;
}
@keyframes next-event-5 {
  0% {
    opacity: 0;
  }
  50% {
    margin-left: -25px;
    opacity: 0;
  }
  80% {
    margin-left: 0px;
    opacity: 1;
  }
}

@mixin next-event__details-button {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: 2s next-event-6 ease-in-out;

  @media (max-width: 500px) {
    i {
      margin: 0;
    }

    span {
      display: none;
    }
  }
}
@keyframes next-event-6 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}

@mixin next-event__circuit-map {
  display: inline-block;
  width: 288px;
  height: 196px;
  float: right;
  opacity: 0.9;
  transition: 0.25s all ease-in-out;
  animation: 2s next-event-7 ease-in-out;

  &:hover {
    transform: scale(1.1) rotateZ(4deg);
  }

  @media (max-width: $m-tablet) {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 180px;
    height: 125px;
    transition: 0.25s all ease-in-out;
  }
  @media (max-width: 800px) {
    transition: 0.25s all ease-in-out;
    right: -210px;
  }
}
@keyframes next-event-7 {
  0% {
    opacity: 0;
    transform: scale(1.2) rotateZ(4deg);
  }
  70% {
    opacity: 0;
    transform: scale(1.2) rotateZ(4deg);
  }
  100% {
    opacity: 0.9;
    transform: scale(1) rotateZ(0deg);
  }
}

@mixin next-event__countdown {
}
