@mixin header {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: $width-header;
  min-width: $min-width-site;
  height: $height-header;
  background-color: $c-header-bg;
  box-shadow: 0px -10px 10px 10px rgba(0,0,0,0);
  transition: .25s all ease-in-out;
  box-sizing: border-box;

  &.header__home {
    background-color: transparent;
  }

  &.is-fixed {
    box-shadow: 0px -10px 10px 10px rgba(0,0,0,1);

    &.header__home {
      background-color: rgba(0,0,0,.75);
    }
  }
}

@mixin header__logo {
  position: absolute;
  z-index: -2;
  display: block;
  padding: 0px 25px;
  line-height: 75px;
  border-radius: 4px;
  overflow: auto;
  float: left;
}

@mixin header__logo-image {
  display: inline-block;
  float: left;
  width: 50px;
  height: 75px;
  margin-right: 15px;
  background-image: url('../assets/img/mw-logo-256.png');
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: 50%;

  @media (max-width: $m-mobile) {
    margin-right: 0px;
  }
}

@mixin header__logo-text {
  float: left;
  font-size: 1.5em;
  font-weight: 100;
  color: $c-header-text;

  .header__home & {
    color: $c-light-grey;
  }

  @media(max-width: 1030px) {
    display: none;
  }

  @media(max-width: $width-content) {
    display: block;
  }

  @media (max-width: $m-mobile) {
    display: none;
  }
}

@mixin header__navigation {
  float: right;
  padding-right: 18px;
}

@mixin header__navigation-button {
  display: inline-block;
  height: 35px;
  line-height: 35px;
  margin: 18px 0px;
  padding: 0px 15px;
  text-decoration: none;
  border: solid 2px transparent;
  border-radius: 4px;
  font-weight: 300;
  color: $c-header-text;
  transition: $t-button;

  .header__home & {
    color: $c-light-grey;
  }

  .header__home &:hover {
    background-color: rgba(255,255,255,.1);
  }
}

@mixin header__navigation-link {
  @include header__navigation-button;

  &:hover {
    background-color: rgba(0,0,0,.05);
  }

  i {
    display: none;
  }

  @media (max-width: $width-content) {
    display: none;
  }
}

@mixin header__navigation-menu-button {
  @include header__navigation-button;

  i {
    display: inline-block;
  }

  display: none;
  width: 20px;
  padding: 0px 8px;
  text-align: center;

  &:before {
    content: '';
    visibility: hidden;
    position: absolute;
    z-index: -1;
    background-color: rgba(0,0,0,0);
    width: 5000px;
    height: 5000px;
    top: -2500px;
    right: -2500px;
    @include timing-function(all);
  }

  &.active:before {
    content: '';
    visibility: visible;
    position: absolute;
    background-color: rgba(0,0,0,0.75);
    width: 5000px;
    height: 5000px;
    top: -2500px;
    right: -2500px;
  }

  &.active {
    background-color: $c-white;
  }

  .header__home &.active {
    background-color: $c-white;
    color: $c-dark-grey;
  }

  @media (max-width: $m-tablet) {
    display: inline-block;
  }
}
