@import '../modules/partners';

.partners {
  @include partners;
}

.partner__row {
  @include partner__row;
}

.partner {
  @include partner;
}
