// Colours
$c-header-bg:             $c-white;
$c-header-text:           $c-dark-grey;
$c-header-button-hover:   rgba(0,0,0,.05);

// Dimensions
$width-header:            $width-wrapper;
$height-header:           75px;

// Transitions
$t-button:                0s ease-in-out;
