@import '../modules/next-event';

.next-event {
  @include next-event;

  &__details {
    @include next-event__details;

    &-caption {
      @include next-event__details-caption;
    }

    &-series {
      @include next-event__details-series;
    }

    &-round {
      @include next-event__details-round;
    }

    &-circuit {
      @include next-event__details-circuit;
    }

    &-date {
      @include next-event__details-date;
    }

    &-button {
      @include next-event__details-button;
    }
  }

  &__circuit-map {
    @include next-event__circuit-map;
  }
}
