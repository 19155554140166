@mixin contact {
  a {
    color: $c-contact-link;
    text-decoration-color: $c-macbook;

    &:hover {
      color: $c-contact-link-hover;
    }
  }
}

@mixin contact__social {
  margin-bottom: 50px;

  .contact__fb i {
    transition: all 0.25s ease-in-out;
    &:hover {
      color: $c-hover-facebook;
    }
  }

  .contact__tw i {
    transition: all 0.25s ease-in-out;
    &:hover {
      color: $c-hover-twitter;
    }
  }

  .contact__ig i {
    transition: all 0.25s ease-in-out;
    &:hover {
      color: $c-hover-instagram;
    }
  }

  .contact__yt i {
    transition: all 0.25s ease-in-out;
    &:hover {
      color: $c-hover-youtube;
    }
  }

  .contact__tt i {
    transition: all 0.25s ease-in-out;
    &:hover {
      color: $c-hover-tiktok;
    }
  }

  a {
    display: inline-block;
    font-size: 2em;
    margin-right: 15px;
  }
}
