@mixin about {
  overflow: auto;

  @media (max-width: $m-tablet) {
    max-width: 650px;
    margin: 0 auto;
  }

  ul {
    padding-left: 0px;
    overflow: hidden;
  }
  li {
    list-style-type: none;
    padding-right: 55px;
    overflow: auto;

    span {
      margin: 0 -55px 0 auto;
      width: 100%;
      float: right;
    }

    @media (max-width: $m-tablet) {
      margin-bottom: 5px;
    }
  }

  i.fa-trophy,
  i.fa-certificate,
  i.fa-star {
    width: 50px;
    text-align: center;
    padding-right: 5px;

    @media (max-width: $m-tablet) {
      font-size: 1.2em;
    }
  }

  i.fa-trophy.trophy-gold,
  i.fa-star.trophy-gold {
    color: darken(gold, 5%);
  }

  i.fa-trophy.trophy-silver {
    color: darken(silver, 10%);
  }

  i.fa-trophy.trophy-bronze {
    color: darken(brown, 5%);
  }

  p a {
    color: $c-contact-link;
    text-decoration-color: $c-macbook;

    &:hover {
      color: $c-contact-link-hover;
    }
  }
}

@mixin about__left {
  width: 25%;
  float: left;

  p {
    &:first-child {
      margin-top: 0px;
    }
  }

  @media (max-width: $m-tablet) {
    float: none;
    width: 100%;
  }
}

@mixin about__right {
  float: right;
  width: calc(75% - 25px);

  @media (max-width: $m-tablet) {
    float: none;
    width: 100%;
  }
}

@mixin about__left-avatar {
  float: left;
  height: auto;
  margin-bottom: 8px;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: $m-tablet) {
    float: none;

    img {
      margin: 0 auto;
      width: 150px;
      height: auto;
      border-radius: 50%;
    }
  }
}

@mixin about__right-text {
  text-align: left;
  line-height: 1.6;

  @media (max-width: $m-tablet) {
    margin: 32px;
  }

  @media (max-width: $m-mobile) {
    margin: 25px;
  }
}

@mixin about__left-images {
  img {
    width: 100%;
    margin-bottom: 8px;
    display: block;
  }

  @media (max-width: $m-tablet) {
    display: none;
    float: left;
    width: 100%;

    img {
      padding: 10px;
      width: calc(25%);
      box-sizing: border-box;
      float: left;
    }
  }
}

@mixin about__images-mobile {
  display: none;

  @media (max-width: $m-tablet) {
    display: block;
    float: left;
    width: 100%;

    img {
      display: block;
      padding: 10px;
      width: 25%;
      box-sizing: border-box;
      float: left;
    }
  }

  @media (max-width: $m-mobile) {
    img {
      width: 50%;
    }
  }
}
