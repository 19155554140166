@import '../modules/about';

.about {
  @include about;

  &__left {
    @include about__left;

    &-avatar {
      @include about__left-avatar;
    }

    &-images {
      @include about__left-images;
    }
  }

  &__right {
    @include about__right;

    &-text {
      @include about__right-text;
    }
  }

  &__images-mobile {
    @include about__images-mobile;
  }
}
