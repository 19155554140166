@mixin news {
  overflow: auto;
}

@mixin news__post {
  display: block;
  position: relative;
  height: $height-post;
  background-size: 100% $height-post, cover;
  background-position: 0px 0px, 50%;
  background-repeat: no-repeat;
  text-decoration: none;
  color: $c-news-post-text;
  overflow: auto;

  &.news__post-older {
    height: $height-post-t;
  }

  @media(max-width: $m-tablet) {
    height: $height-post-t;
  }
}

@mixin news__posts-older {
  overflow: auto;
}

@mixin news__post-latest {
  width: 100%;
  margin-bottom: 26px;
}

@mixin news__post-older {
  float: left;
  width: calc(50% - 13px);
  margin-bottom: 26px;

  &:nth-child(even) {
    float: right;
  }

  @media(max-width: 750px) {
    width: 100%;
    float: none;
    margin-bottom: 26px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

@mixin news__post-title {
  width: 600px;
  margin: 0;
  padding: 16px 16px 0px 16px;
  font-size: 2em;
  color: $c-light-grey;
  font-weight: 500;
  box-sizing: border-box;

  .news__post-older & {
    width: 100%;
    padding-top: 32px;
  }

  @media(max-width: $m-tablet) {
    width: 466px;
  }

  @media(max-width: 750px) {
    width: 100%;
    padding-top: 32px;
  }
}

@mixin news__post-short-description {
  width: 600px;
  margin: 0;
  padding: 8px 16px 0px 16px;
  box-sizing: border-box;

  .news__post-older & {
    width: 100%;
    padding-top: 4px;
  }

  @media(max-width: $m-tablet) {
    width: 466px;
  }

  @media(max-width: 750px) {
    width: 100%;
    padding-top: 4px;
  }
}

@mixin news__post-date {
  position: absolute;
  top: 16px;
  right: 16px;
  color: $c-news-date;
  font-weight: 500;
  font-style: italic;

  .news__post-older & {
    position: relative;
    left: 16px;
  }

  @media(max-width: 750px) {
    position: relative;
    left: 16px;
  }
}

@mixin news__post-buttons {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

@mixin news__post-action {
  float: left;
}

@mixin news__post-tags {
  margin-top: 15px;
  float: right;
}

@mixin news__post-tag {
  margin-right: 8px;
  padding: 5px 8px;
  font-size: 0.75em;

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: $m-tablet) {
    margin-right: 0px;
    &:not(:first-child) {
      display: none;
    }
  }

  @media(max-width: 750px) {
    margin-right: 8px;
    &:not(:first-child) {
      display: inline-block;
    }
  }

  @media(max-width: $m-mobile) {
    margin-right: 0px;
    &:not(:first-child) {
      display: none;
    }
  }
}

@mixin news__more-news {
  text-align: center;
  padding: 50px 0px 25px 0px;

  @media(max-width: $m-tablet) {
    padding: 25px 0px 10px 0px;
  }
}

@mixin pagination {
  width: 100%;
  overflow: auto;
  padding: 25px 0px;
}

@mixin pagination__section-left {
  float: left;
  width: 30%;
}

@mixin pagination__section-centre {
  float: left;
  width: 40%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

@mixin pagination__section-right {
  float: right;
  width: 30%;
}

@mixin pagination__previous {
  @media(max-width: 550px) {
    span {
      display: none;
    }
    i {
      margin: 0;
    }
  }
}

@mixin pagination__page-number {
  color: $c-graphite;
}

@mixin pagination__next {
  float: right;

  i {
    margin-right: 0;
    margin-left: 8px;
  }

  @media(max-width: 550px) {
    span {
      display: none;
    }
    i {
      margin: 0;
    }
  }
}
