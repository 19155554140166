html,
body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  color: $base-font-colour;
  background-color: $c-site-background;
  margin: 0;
  height: 100%;
}

body.no-scroll {
  overflow: hidden;
}

body.no-transitions * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
